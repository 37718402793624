import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
} from '../styles';
import { Button, Container, Heading } from './ui';

const StyledBlogAuthor = styled.section`
  ${sectionMargins('20px', '40px')};
`;

const StyledInner = styled.div`
  background-color: ${brandColours.primary};
  color: ${standardColours.white};
  padding: 30px;

  ${minBreakpointQuery.small`
    padding: 35px;
  `}

  ${minBreakpointQuery.medium`
    padding: 40px;
  `};

  ${minBreakpointQuery.large`
    padding: 45px;
  `};

  ${minBreakpointQuery.xxlarge`
    padding: 50px;
  `};
`;

const StyledTop = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  ${minBreakpointQuery.small`
    gap: 30px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  max-width: 60px;
  max-height: 60px;

  ${minBreakpointQuery.small`
    max-width: 90px;
    max-height: 90px;
  `}

  ${minBreakpointQuery.large`
    max-width: 120px;
    max-height: 120px;
  `}
`;

const StyledText = styled.p`
  white-space: break-spaces;
  margin-top: 20px;
  line-height: 1.7;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
    margin-top: 30px;
  `}
`;

const StyledLink = styled(Button)`
  margin-top: 30px;

  ${minBreakpointQuery.small`
    ${fontSize(18)};
    margin-top: 35px;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)};
    margin-top: 40px;
  `}
`;

const BlogAuthor = ({ author }) => {
  const { image, title, biography, slug } = author;
  const urlPath = `blog/${slug}`;

  return (
    <StyledBlogAuthor>
      <Container blogNarrow={true}>
        <StyledInner>
          <StyledTop>
            <StyledImage image={image.gatsbyImageData} alt={image.alt} />
            <Heading removeWave={true}>{title}</Heading>
          </StyledTop>
          <StyledText>{biography}</StyledText>
          <StyledLink to={urlPath} alt={true}>
            More by {title}
          </StyledLink>
        </StyledInner>
      </Container>
    </StyledBlogAuthor>
  );
};

export default BlogAuthor;
