import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import {
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Button, Heading, Container } from './ui';
import BlogList from './BlogList';

const StyledFeaturedBlogPosts = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  ${minBreakpointQuery.smedium`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  `}
`;

const StyledButton = styled(Button)`
  ${maxBreakpointQuery.smedium`
    margin-top: 30px;
    width: 100%;
  `}
`;

const FeaturedBlogPosts = ({
  heading,
  items: overrideItems,
  isBlogListing,
}) => {
  const {
    allDatoCmsBlog: { nodes },
    datoCmsBlogArchive: link,
  } = useStaticQuery(graphql`
    query FeaturedBlogPostsQuery {
      allDatoCmsBlog(
        sort: { fields: meta___firstPublishedAt, order: DESC }
        limit: 6
      ) {
        nodes {
          ...BlogCardFragment
          featuredImage {
            ...OtherBlogCardFeaturedImageFragment
          }
        }
      }
      datoCmsBlogArchive {
        ...LinkFragment
      }
    }
  `);

  const items =
    overrideItems && overrideItems.length > 0 ? overrideItems : nodes;

  return (
    <StyledFeaturedBlogPosts>
      <Container>
        <StyledHeader>
          <Heading>{heading}</Heading>
          {link && (
            <StyledButton to={link.slug}>View all articles</StyledButton>
          )}
        </StyledHeader>
      </Container>
      <BlogList
        items={items}
        isBlogListing={isBlogListing}
        isFeaturedBlogPosts={true}
      />
    </StyledFeaturedBlogPosts>
  );
};

export default FeaturedBlogPosts;
