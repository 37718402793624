import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import ContainedImage from '../components/ContainedImage';
import ModularBlocks from '../components/ModularBlocks';
import BlogAuthor from '../components/BlogAuthor';
import FeaturedBlogPosts from '../components/FeaturedBlogPosts';
import { buildUrl } from '../utils';

const BlogSingleTemplate = ({
  data: {
    datoCmsBlog: {
      seoMetaTags,
      title,
      meta: { firstPublishedAt, firstPublishedAtFormatted, updatedAt },
      category,
      author,
      featuredImage,
      modularBlocks,
    },
    allDatoCmsBlog: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags}>
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": "${title}",
          "image": [
            "${featuredImage.gatsbyImageData.images.fallback.src}"
          ],
          "datePublished": "${firstPublishedAt}",
          "dateModified": "${updatedAt}",
          "author": [{
            "@type": "Person",
            "name": "${author.title}",
            "url": "${
              process.env.GATSBY_SITE_URL + buildUrl(author.slug, author)
            }"
          }]
        }`}
      </script>
    </HelmetDatoCms>
    <main>
      <PageHeader
        overline={category.title}
        heading={title}
        text={firstPublishedAtFormatted}
        author={author}
        breadcrumbs={[
          { slug: 'blog', text: 'Blog' },
          { slug: `blog/${category.slug}`, text: category.title },
        ]}
      />
      <ContainedImage image={featuredImage} isBlogSingleFeatured={true} />
      <ModularBlocks items={modularBlocks} isBlogSingle={true} />
      <BlogAuthor author={author} />
      <FeaturedBlogPosts heading="Related Articles" items={nodes} />
    </main>
  </Layout>
);

export const BlogSingleTemplateQuery = graphql`
  query BlogSingleTemplateQuery($id: String!, $categoryId: String) {
    datoCmsBlog(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      meta {
        firstPublishedAt
        firstPublishedAtFormatted: firstPublishedAt(
          formatString: "Do MMMM YYYY"
        )
        updatedAt
      }
      category {
        title
        slug
      }
      featuredImage {
        gatsbyImageData(width: 1220, height: 500)
        alt
      }
      author {
        title
        biography
        image {
          gatsbyImageData(width: 160, height: 160)
          alt
        }
        ...LinkFragment
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HighlightedTextModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
    }
    allDatoCmsBlog(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { category: { id: { eq: $categoryId } }, id: { ne: $id } }
      limit: 6
    ) {
      nodes {
        ...BlogCardFragment
        featuredImage {
          ...OtherBlogCardFeaturedImageFragment
        }
      }
    }
  }
`;

export default BlogSingleTemplate;
