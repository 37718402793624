import React from 'react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  standardTransition,
  brandFonts,
  minBreakpointQuery,
} from '../styles';
import { Slider, Link } from './ui';

const StyledListSlider = styled.div`
  margin: 30px 0;

  ${minBreakpointQuery.small`
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`;

const StyledItems = styled(Slider)`
  padding: 0 30px;

  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    text-align: center;
    width: auto;
    height: 55px;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    background-color: ${standardColours.white};
    height: 100%;
    width: 30px;
    margin-top: -22px;
    top: 22px;
    z-index: 1;

    &:after {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 1px;
      top: 50%;
      border: 3px solid ${brandColours.primary};
      border-top: none;
      border-left: none;
      z-index: 1;
    }
  }

  .swiper-button-prev {
    left: 0;

    &:before {
      transform: translateY(-50%) rotate(135deg);
      transition: ${standardTransition('border-color')};
      left: 3px;
    }
  }

  .swiper-button-next {
    right: 0;

    &:before {
      transform: translateY(-50%) rotate(-45deg);
      transition: ${standardTransition('border-color')};
      right: 3px;
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 16px 0;
  font-family: ${brandFonts.secondary};
  white-space: nowrap;
  border-top: 1px solid ${standardColours.lightGrey};
  border-bottom: 1px solid ${standardColours.lightGrey};
  ${standardTransition('all')};

  &:hover,
  &.current-page {
    color: ${brandColours.primary};
    border-color: ${brandColours.primary};
  }
`;

const ListSlider = ({ parentSlug, firstSlideText, items }) => {
  const sliderOptions = {
    modules: [Navigation],
    slidesPerView: 'auto',
    spaceBetween: 30,
    navigation: true,
  };

  return (
    <StyledListSlider>
      <StyledItems
        firstSlide={<StyledLink to={parentSlug}>{firstSlideText}</StyledLink>}
        isList={true}
        {...sliderOptions}
      >
        {items.map(({ text, slug }, id) => (
          <StyledLink
            key={id}
            to={`${parentSlug}/${slug}`}
            activeClassName="current-page"
          >
            {text}
          </StyledLink>
        ))}
      </StyledItems>
    </StyledListSlider>
  );
};

export default ListSlider;
