import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, sectionMargins, fontSize } from '../styles';
import { Container } from './ui';
import ListSlider from '../components/ListSlider';
import BlogCard from '../components/BlogCard';

const StyledBlogList = styled.section`
  ${({ isFeaturedBlogPosts }) => {
    if (!isFeaturedBlogPosts) {
      return css`
        ${sectionMargins(undefined, '60px')};
      `;
    }
  }}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 25px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.small`
    row-gap: 30px;
  `}

  ${minBreakpointQuery.medium`
    row-gap: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.large`
    row-gap: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    row-gap: 50px;
  `}
`;

const StyledItem = styled(BlogCard)`
  ${({ isBlogListing }) => {
    if (isBlogListing) {
      return css`
        &:first-child {
          grid-column: 1 / -1;

          ${minBreakpointQuery.small`
            display: grid;
            grid-template-columns: 1.2fr 1fr;
          `}

          ${minBreakpointQuery.medium`
            grid-template-columns: 1.4fr 1fr;
          `}

          ${minBreakpointQuery.large`
            grid-template-columns: 1.6fr 1fr;
          `}

          h2 {
            ${fontSize(26)};

            ${minBreakpointQuery.small`
              ${fontSize(30)};
            `}

            ${minBreakpointQuery.medium`
              ${fontSize(34)};
            `}

            ${minBreakpointQuery.large`
              ${fontSize(38)};
            `}
          }
        }
      `;
    }
  }}
`;

const BlogList = ({ items, isBlogListing, isFeaturedBlogPosts }) => {
  const {
    allDatoCmsBlogCategory: { nodes },
  } = useStaticQuery(graphql`
    query BlogListQuery {
      allDatoCmsBlogCategory {
        nodes {
          text: title
          slug
        }
      }
    }
  `);

  return (
    items.length > 0 && (
      <StyledBlogList isFeaturedBlogPosts={isFeaturedBlogPosts}>
        <Container>
          {isBlogListing && (
            <ListSlider
              parentSlug="blog"
              firstSlideText="All Posts"
              items={nodes}
            />
          )}
          <StyledItems>
            {items.map(
              ({
                id,
                title,
                slug,
                publishedDate,
                category,
                author,
                featuredImage,
              }) => (
                <StyledItem
                  key={id}
                  image={featuredImage}
                  category={category}
                  author={author}
                  heading={title}
                  slug={slug}
                  publishedDate={publishedDate}
                  isBlogListing={isBlogListing}
                  isFeaturedBlogPosts={isFeaturedBlogPosts}
                />
              )
            )}
          </StyledItems>
        </Container>
      </StyledBlogList>
    )
  );
};

export default BlogList;
