import React from 'react';
import { navigate, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontSize,
} from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

const StyledBlogCard = styled.article`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledImageWrapper = styled.div``;

const StyledImage = styled(GatsbyImage)`
  height: 100%;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 100%;
  font-family: ${brandFonts.secondary};
  background-color: ${standardColours.lighterGrey};

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
    padding: 30px;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: repeat(3, 1fr);
    padding: 40px;
  `}
`;

const StyledCategory = styled.p`
  ${fontSize(12)};
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const StyledHeading = styled.h2`
  margin: 20px 0 30px;
  ${fontSize(18)};

  ${minBreakpointQuery.small`
    ${fontSize(21)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(24)};
  `}
`;

const StyledDate = styled.time`
  margin-top: auto;
  ${fontSize(14)};

  ${minBreakpointQuery.small`
    ${fontSize(15)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(17)};
  `}
`;

const StyledAuthor = styled.p`
  margin-top: 10px;
  ${fontSize(14)};

  ${minBreakpointQuery.small`
    ${fontSize(15)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(17)};
  `}
`;

const BlogCard = ({
  image: { gatsbyImageData, alt },
  category,
  heading,
  slug,
  publishedDate,
  author,
  isFeaturedBlogPosts,
  ...props
}) => {
  const urlPath = `blog/${category.slug}/${slug}`;

  return (
    <StyledBlogCard
      onClick={() => {
        navigate(buildUrl(urlPath));
      }}
      {...props}
    >
      <StyledImageWrapper>
        <StyledImage image={gatsbyImageData} alt={alt} />
      </StyledImageWrapper>
      <StyledContent>
        <StyledCategory>{category.title}</StyledCategory>
        <StyledHeading as={isFeaturedBlogPosts && 'h3'}>
          <Link to={urlPath}>{heading}</Link>
        </StyledHeading>
        <StyledDate dateTime={publishedDate.robotDate}>
          {publishedDate.humanDate}
        </StyledDate>
        <StyledAuthor>{`By ${author.title}`}</StyledAuthor>
      </StyledContent>
    </StyledBlogCard>
  );
};

export default BlogCard;

export const BlogCardFragment = graphql`
  fragment BlogCardFragment on DatoCmsBlog {
    id
    title
    slug
    publishedDate: meta {
      robotDate: firstPublishedAt
      humanDate: firstPublishedAt(formatString: "Do MMMM YYYY")
    }
    category {
      title
      slug
    }
    author {
      title
    }
  }

  fragment LatestBlogCardFeaturedImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 750, height: 460)
    alt
  }

  fragment OtherBlogCardFeaturedImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 460, height: 280)
    alt
  }
`;
